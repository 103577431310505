const validateEmail = value => {
  // empty value
  if (!value) {
    return 'Email is required.'
  }

  // not a valid email
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return 'This field must be a valid email'
  }

  return true
}

const validateName = value => {
  if (!value) {
    return 'Name is required.'
  }

  return true
}

const validateMessage = value => {
  if (!value) {
    return 'Message is required'
  }

  return true
}

export { validateEmail, validateName, validateMessage }

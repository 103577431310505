<template>
  <p class="fixed bottom-0 right-0 p-3 pr-24 mb-6 mr-2 text-sm rounded-lg">
    <slot />
  </p>
</template>

<script>
export default {
  name: 'ContactFormAlert'
}
</script>

<template>
  <div class="flex flex-col h-full justify-center items-center">
    <h1 class="title">
      Contact me
    </h1>
    <hr class="bg-lightBlue-500 h-1 border-none mt-1 w-24 md:w-32 lg:w-40" />
    <p class="text mt-8 text-center w-10/12 max-w-2xl">
      Have any questions or have a project you want to work on? Don't hesitate
      to contact me.
    </p>
    <ContactForm />
  </div>
</template>

<script>
import ContactForm from '@/components/ContactForm.vue'

export default {
  name: 'Contact',
  components: { ContactForm }
}
</script>

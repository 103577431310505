<template>
  <Form
    @submit="onSubmit"
    v-slot="{ errors }"
    class="flex flex-col justify-center items-left mt-8 w-10/12 max-w-2xl"
  >
    <Field
      name="name"
      class="input-text"
      type="input"
      placeholder="Name"
      :rules="validateName"
      :class="{ 'input-error': errors.name }"
    />
    <div
      class="w-full mb-3 input-div"
      :class="{ 'input-div-error': errors.name }"
    />
    <Field
      name="email"
      class="input-text"
      type="email"
      placeholder="Email"
      :rules="validateEmail"
      :class="{ 'input-error': errors.email }"
    />
    <div
      class="w-full mb-3 input-div"
      :class="{ 'input-div-error': errors.email }"
    />
    <Field
      name="message"
      class="input-text h-32"
      as="textarea"
      placeholder="Message"
      :rules="validateMessage"
      :class="{ 'input-error': errors.message }"
    />
    <div
      class="w-full mb-3 input-div"
      :class="{ 'input-div-error': errors.message }"
    />
    <button class="btn w-24 self-end text-sm md:text-base">Submit</button>
  </Form>
  <transition name="slide-fade">
    <ContactFormAlert
      v-if="msg.show"
      :class="{ 'bg-green-500': !msg.error, 'bg-red-500': msg.error }"
      >{{ msg.content }}</ContactFormAlert
    >
  </transition>
</template>

<script>
import { ref } from 'vue'
import ContactFormAlert from '@/components/ContactFormAlert.vue'
import { Form, Field } from 'vee-validate'
import { send } from 'emailjs-com'
import {
  validateEmail,
  validateName,
  validateMessage
} from '@/utils/validation.js'

export default {
  name: 'ContactForm',
  components: { ContactFormAlert, Form, Field },
  setup() {
    const msg = ref({
      show: false,
      error: false,
      content: ''
    })

    function resetMsg() {
      setTimeout(
        () => (msg.value = { show: false, error: false, content: '' }),
        3000
      )
    }

    function onSubmit(values, { resetForm }) {
      const templateParams = {
        name: values.name,
        email: values.email,
        message: values.message
      }
      send(
        process.env.VUE_APP_SERVICE_ID,
        process.env.VUE_APP_TEMPLATE_ID,
        templateParams
      ).then(
        () => {
          resetForm()
          msg.value = {
            show: true,
            error: false,
            content: 'Message sent successfully.'
          }
          resetMsg()
        },
        error => {
          console.log(error)
          msg.value = {
            show: true,
            error: true,
            content: 'Could not send message.'
          }
          resetMsg()
        }
      )
    }

    return {
      msg,
      onSubmit,
      validateEmail,
      validateName,
      validateMessage
    }
  }
}
</script>

<style scoped>
input:focus::placeholder,
textarea:focus::placeholder {
  color: transparent;
}

.input-div {
  height: 2px;
  background: transparent;
  width: 0;
  transition: all 0.5s ease-out;
}

input:focus + .input-div,
textarea:focus + .input-div {
  background: #0ea5e9;
  width: 100%;
  transition: all 0.5s ease-out;
}

input + .input-div-error,
textarea + .input-div-error {
  background: red;
  width: 100%;
  transition: all 0.5s ease-out;
}
</style>
